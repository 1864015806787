import { FunctionComponent } from "react";
import { TableCell, TableRow } from '@mui/material';
import { GrowthStages, PlayerDetails } from "../../../api/server-players.types";
import { PlayerActions } from "./PlayerActions";
import { ServerModel } from "../../../api/server.types";
import { SelectOption } from "../../../components/common/Select";
import { Location } from "../../../api/locations.types";

const GROWTH_STAGE_FRIENDLY: { [key: string]: string } = {
    [GrowthStages.JUVINILE]: 'Juvinile',
    [GrowthStages.ADOLECENT]: 'Adolecent',
    [GrowthStages.SUB_ADULT]: 'Sub Adult',
    [GrowthStages.ADULT]: 'Adult',
}

interface PlayerRowProps {
    server: ServerModel;
    player: PlayerDetails;
    locations: SelectOption<Location>[];
    hideAGID?: boolean;
}

export const PlayerRow: FunctionComponent<PlayerRowProps> = ({ server, player, hideAGID, locations }) => {
    const { name, agid, dinosaur, role, marks: playerMarks, growth } = player

    return (
        <TableRow key={agid}>
            <TableCell>{name}</TableCell>
            {!hideAGID && <TableCell>{agid}</TableCell>}
            <TableCell>{dinosaur}</TableCell>
            <TableCell>{role}</TableCell>
            <TableCell>{playerMarks}</TableCell>
            <TableCell>{GROWTH_STAGE_FRIENDLY[growth]}</TableCell>
            <TableCell>
                <PlayerActions server={server} player={player} locations={locations} />
            </TableCell>
        </TableRow>
    );
}